<template>
  <v-hover v-slot="{ hover }">
    <v-expand-x-transition>
      <v-card 
        v-show="toggle"
        :width="width"
        max-height="100%"
        :flat="flat"
        class="step rounded"
      >
        <v-progress-linear
          :active="loading"
          indeterminate
          absolute
          top
          height="2"
        />
        <slot name="header">
          <v-subheader class="text-overline">
            {{ title }}
            <v-badge
              :value="count>0"
              :content="count"
              inline
              color="grey"
              class="ml-1"
            />
          </v-subheader>
        </slot>
        <v-btn
          v-show="!loading&&status!=null"
          color="grey"
          icon
          absolute
          top
          right
          class="mr-n2"
          style="margin-top: -10px;"
          @click="update"
        >
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>

        <v-divider v-show="toolbar&&!empty" />

        <v-list 
          :height="maxHeight"
          :class="[toolbar&&!empty ? 'pb-12' : 'py-0']"
          class="transparent scrollable"
        >
          <v-list-item 
            v-for="d in orderedList"
            :key="d.id"
            @click="getProfile(d.id)"
          >
            <slot 
              name="item-avatar" 
              :driver="d"
            >
              <v-list-item-avatar
                v-if="breakpoint('xs')"
              >
                <v-progress-circular
                  v-if="(d.id==getting)&&loadingDriver"
                  indeterminate
                  absolute
                  top
                  size="24"
                  width="2"
                  color="primary"
                />
                <v-img 
                  v-else-if="d.photo"
                  :src="d.photo" 
                />
                <icon-base
                  v-else
                  width="96"
                  height="96"
                  icon-name="avatar"
                  class="avatar-default"
                >
                  <avatar-default />
                </icon-base>
              </v-list-item-avatar>
            </slot>
            <slot 
              name="item-content" 
              :driver="d"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ d.fullname }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <b v-if="!!d.vehicle">{{ d.vehicle.model }} {{ d.vehicle.isOwner | isOwnerToText }}</b> | {{ !!d.partner&&d.partner ? 'Buzzer '+d.buzzer.number : formatCPF(d.cpf) }}
                  <!-- | Avaliação:  -->
                </v-list-item-subtitle>
              </v-list-item-content>
            </slot>
            <v-list-item-action 
              v-if="selectable&&(hover||selected[d.id]||input.actions.toggle)"
              class="mr-2"
            >
              <v-checkbox
                :input-value="selected[d.id]"
                readonly
                @click.stop="selectDriver(!(selected[d.id]), d.id)"
              />
            </v-list-item-action>
            <v-list-item-action v-else-if="status=='SC+'">
              <v-list-item-action-text>
                {{ d.calendar.start | day }}
              </v-list-item-action-text>
              <v-list-item-action-text>
                {{ d.calendar.start | hour }}
              </v-list-item-action-text>
            </v-list-item-action>
            <slot 
              name="item-actions" 
              :driver="d"
            />
          </v-list-item>
        </v-list>

        <v-toolbar 
          v-if="toolbar&&!empty"
          flat
          absolute 
          bottom
          dense
          width="100%"
          class="list-bottom-toolbar"
        >
          <v-checkbox
            :input-value="allSelected"
            :indeterminate="selectedCount!=0&&selectedCount!=all.length"
            :disabled="all.length==0"
            readonly
            hide-details
            @click.stop="selectAll"
          >
            <template v-slot:label>
              <span 
                :class="{ 'primary--text': selectedCount }"
                class="text-body-2 font-weight-medium"
              >
                {{ selectedCount ? (selectedCount==1 ? selectedCount + ' selecionado' : selectedCount + ' selecionados') : 'Selecionar todos' }}
              </span>
            </template>
          </v-checkbox>
          <v-spacer />
          <v-menu
            v-model="input.actions.toggle"
            transition="slide-y-transition"
            left
            top
            offset-y
            min-width="240px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :disabled="selectedCount==0"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon v-text="icons.mdiDotsVertical" />
              </v-btn>
            </template>
            <slot 
              name="actions"
              :selected="parseSelected"
            >
              <v-list>
                <v-list-item
                  @click="toggleMessenger(true)"
                >
                  <v-list-item-title>Enviar comunicado</v-list-item-title>
                </v-list-item>
              </v-list>
            </slot>
          </v-menu>
        </v-toolbar>
      </v-card>
    </v-expand-x-transition>
  </v-hover>
</template>

<style>

  .scope {
    position: relative;
  }

  .scope .loading {
    opacity: .25;
  }

  .scope .v-btn__content {
    position: initial;
  }

  .list-bottom-toolbar {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  
</style>

<script>
  // Icons
  import { mdiViewColumnOutline, mdiRefresh, mdiDotsVertical } from '@mdi/js';
  import services from '@/services'
  var moment = require('moment');

  export default {
    components: {
      IconBase: () => import('@/components/IconBase'),
      AvatarDefault: () => import('@/components/icons/AvatarDefault'),
    },

    props: {
      status: {
        type: String,
        default: null
      },
      title: {
        type: String,
        default: null
      },
      list: {
        type: [Array, Object],
        default: () => {
          return []
        }
      },
      order: {
        type: Array,
        default: () => null
      },
      loading: {
        type: Boolean,
        default: false
      },
      toggle: {
        type: Boolean,
        default: true
      },
      loadingDriver: {
        type: Boolean,
        default: false
      },
      driver: {
        type: String,
        default: null
      },
      maxHeight: {
        type: [Number, String],
        default: '100%'
      },
      width: {
        type: [Number, String],
        default: '280px'
      },
      flat: {
        type: Boolean,
        default: false
      },
      toolbar: {
        type: Boolean,
        default: true
      },
      threeLine: {
        type: Boolean,
        default: false
      },
      selectable: {
        type: Boolean,
        default: true
      },
    },

    data: () => ({
      icons: {
        mdiViewColumnOutline,
        mdiRefresh,
        mdiDotsVertical
      },
      input: {
        search: {
          value: '',
          default: '',
          loading: false,
          disabled: false,
          label: 'Busca',
          messages: []
        },
        actions: {
          toggle: false,
          loading: false,
          disabled: false,
        }
      },
      options: {
        order: [['rating', 'job.rating', 'fullname'], ['desc', 'desc', 'asc']],
      },
      selected: {},
      getting: null
    }),

    computed: {
      orderedList () {
        const order = _.isNil(this.order) ? this.options.order : this.order;
        return _.orderBy(this.list, ...order);
      },

      listHeight () {
        return 'calc(100vh - '+ (this.breakpoint(null, 'sm') ? '218px' : '218px') + ')';
      },

      count () {
        return this.list.length;
      },

      empty () {
        return this.count.length==0;
      },

      selectedCount () {
        return _.filter(this.selected, s => s).length;
      },

      all () {
        return _.map(this.list, d => d.id);
      },

      allSelected () {
        return _.every(this.selected, s => s==true);
      },

      parseSelected () {
        return _.map(_.omitBy(this.selected, s => s==false), (d, id) => {
          const driver = _.find(this.list, ['id', parseInt(id)]);
          driver.name = driver.fullname.split(' ');
          driver.name = driver.name[0] + ' ' + driver.name[driver.name.length-1];
          return {
            ...driver,
            id: driver.id,
            partner_id: driver.partner_id,
            email: driver.email,
            cpf: driver.cpf,
            name: driver.name
          }
        });
      },

    },

    watch: {
      driver (v) {
        this.getting = null;
      },
      list: {
        immediate: true,
        handler (list) {
        this.selected = Object.assign({}, _.mapValues(_.mapKeys(list, d => d.id), c => false), this.selected);
        }
      }
    },
    
    methods: {
      ...services,

      selectAll () {
        const b = !this.allSelected;
        _.each(this.all, id => {
          this.selectDriver(b, id);
        });
      },

      selectDriver (b, id) {
        this.$set(this.selected, id, b);
      },

      toggleMessenger (b) {
        const to = this.parseSelected;
        console.log('messenger:', b, to);
        this.$emit('toggle-messenger', true, to);
      },

      getProfile (id) {
        this.getting = id;
        this.$emit('get-profile', id);
      },
      update () {
        this.$emit('update', this.status);
      }
    },

    filters: {
      day (value) {
        return moment(value).format('DD/MM');
      },
      hour (value) {
        return moment(value).format('HH:mm');
      },
      isOwnerToText (value) {
        return !_.isNil(value) ? value == 0 ? 'Emprestado' : value == 1 ? 'Próprio' : value == 2 ? 'Alugado' : '–' : '–';
      },
    },

    mounted () {
      
    }
  }
</script>